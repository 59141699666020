import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CustomSweetAlert, Loading } from '../../components/common';
import { withFirebase } from '../../components/Firebase';
import DashboardLayout from '../Dashboard';
import { toast } from 'react-toastify';
import "./style.css";

function SurveysList(props) {
    const { firebase } = props;
    const [surveysList, setSurveysList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "default",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });

    useEffect(() => {
        if (firebase) {
            getSurveysList();
        }
        return () => {

        };
    }, [firebase])

    const getSurveysList = () => {
        setIsLoading(true);
        let ref = firebase.db.collection("/surveys");
        ref.onSnapshot((data) => {
            let list = []
            data.forEach(doc => {
                list = list.concat({ id: doc.id, ...doc.data() })
            });
            setSurveysList(list);
            setIsLoading(false);
        })
    }

    const onSurveyDelete = async (id) => {
        setSweetAlert({
            showCancel: true,
            show: true,
            type: "warning",
            title: "Are you sure?",
            message: "You want to Delete this survey.",
            onConfirm: () => {
                let ref = firebase.db.collection("/surveys").doc(id);
                setSweetAlert({ ...setSweetAlert, show: false })
                ref.delete().then(() => {
                    toast.success('Survey successfully deleted.');
                }).catch((error) => {
                    console.log("DELETE Error:", error)
                });
            },
            onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
        })
    }

    return (
        <>
            <DashboardLayout>
                {isLoading ? <Loading /> : null}
                <div className="ds-title">
                    <div className="ds-container">
                        <div className="title">
                            <h1>Surveys</h1>
                            <button className="title-btn" onClick={() => navigate("new-surveys")}>Add Survey</button>
                        </div>
                    </div>
                </div>

                <div className="ds-container">
                    <section className="surveys-list">
                        <div className="sub-title">
                            <h3>Surveys List</h3>
                        </div>

                        <div className="items">
                            {surveysList && surveysList.map((survey, index) =>
                                <div className="item" key={index}>
                                    <div className="title">
                                        <span className="name"> {survey.name} </span>
                                        <span> Active </span>
                                    </div>

                                    <div className="options">
                                        <span onClick={() => onSurveyDelete(survey.id)}><i className="fa fa-trash-o" aria-hidden="true"></i> Delete</span>
                                        <span onClick={() => navigate("edit-surveys/" + survey.id)}><i className="fa fa-pencil" aria-hidden="true"></i> Edit</span>
                                        <span onClick={() => navigate("surveys-result/" + survey.id)}><i className="fa fa-bar-chart" aria-hidden="true"></i> Results</span>
                                    </div>
                                </div>
                            )}

                        </div>
                    </section>
                </div>
            </DashboardLayout>
            <CustomSweetAlert {...SweetAlert} />
        </>
    )
}

export default withFirebase(SurveysList);
