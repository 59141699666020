import React from 'react'
import { compose } from 'recompose'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'
import SurveysList from '../../../Views/Surveys/SurveysList'

const IndexPage = () => {

    return (
        <>
            <SEO title="Surveys" />
            <AuthUserContext.Consumer>
                {authUser => (
                    <SurveysList />
                )}
            </AuthUserContext.Consumer>
        </>
    )
}

const condition = authUser => !!authUser

export default compose(
	withAuthorization(condition),
)(IndexPage)